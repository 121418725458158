import React from 'react';
import './App.css';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Bienvenida from './componentes/bienvenida/Bienvenida'
import axios from 'axios'


class App extends React.Component {

  componentDidMount() {
    this.getLocation()
  }

  state = {
    country: ''
  }

  fadeOut = () => {
    const content_off = document.querySelector('.content_off')
    const lineUp = document.querySelector('.lineUp')
    const lineUpMovil = document.querySelector('.lineUpMovil')
    content_off.classList.toggle('fadeOut')
    lineUp.classList.toggle('fadeInLine')
    lineUpMovil.classList.toggle('fadeInLine')
  }


  getLocation = async () => {
    await axios.get('https://ipapi.co/json/?key=6hBzAYQpmTnjjltlK714GR6iP0SqvVEGuoTEWs4iWPnlG3IbvI')
      .then(res => {

        this.setState({
          country: res.data.country
        })
      })

  }



  render() {
    return (
      <div className="App">
        <div className="carita carita_amarilla"></div>
        <div className="carita carita_amarilla_little"></div>
        <div className="carita carita_verde"></div>
        <div className="carita carita_verde_big desktop"></div>
        <div className="carita carita_azul desktop"></div>
        <div className="carita carita_morado"></div>
        <div className="carita carita_morado_little"></div>
        <div className="carita carita_roja"></div>

        {this.state.country === 'CO' &&
          <div>
            {/* <div className="programacion" onClick={this.fadeOut}></div> */}

            {/* <div className="lineUp desktop">
              <div className="cerrar" onClick={this.fadeOut}>X</div>
              <Galeria />
            </div> */}

            {/* MOVIL
            <div className="programacion" onClick={this.fadeOut}></div>
            <div className="lineUpMovil movil">
              <div className="cerrar" onClick={this.fadeOut}>X</div>
              <Galeria />
            </div> */}
          </div>

        }



        <div className="content_off">
          {this.state.country === 'CO' &&
            <Router>
              {/* Cada componente se encuentra en la carpeta de 'componentes'*/}
              <Switch>
                <Route exact path="/" component={Bienvenida} />
                {/* <Route exact path="/terminosycondiciones" component={Terminos} />
                <Route exact path="/count" component={Conteo} />
                <Route path="/count/:number/:operator" component={Conteo2} /> */}
              </Switch>

            </Router>
          }

          {this.state.country !== 'CO' &&
            <div className="bienvenida_contenedor fadeCo">
              <div>
                <h2>CONTENIDO EXCLUSIVO PARA COLOMBIA</h2>

              </div>
            </div>
          }
        </div>

        {/* <div className="logos_footer">
          <img className="lateral" src={barra_logos_lateral} alt="barra de logos" />
          <img className="centro" src={barra_logos} alt="barra de logos" />
        </div> */}

      </div>
    );
  }
}

export default App;
