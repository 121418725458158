import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import './Bienvenida.css'
import logoresiste from '../../assets/img/generales/logo-fep-resiste.png'
import boton_tienda from '../../assets/img/generales/boton-tienda.png'

class Bienvenida extends React.Component {
    state = {
        status: null
    }

    selected_yes = () => {
        const bienvenida = document.querySelector('.bienvenida');
        bienvenida.classList.add('fadeIn')

        setTimeout(

            function () {
                this.setState({
                    status: 'yes'
                });
            }
                .bind(this),
            500
        );
    }

    selected_no = () => {
        const modal_contenedor = document.querySelector('.modal_contenedor');
        modal_contenedor.classList.toggle('show_modal')
    }


    render() {
        if (this.state.status === 'yes') {
            return <Redirect to="/count" />
        }

        return (
            <section className="bienvenida">
                <div className="bienvenida_contenedor">
                    <img className="logoResiste" src={logoresiste} alt="Resiste" />
                    <a className="boton_tienda_resiste" href="https://ecommerce3.entradasamarillas.com/">   
                    <img  src={boton_tienda} alt="Tienda" /></a>

                    <div>
                        {/* <h2>¿ERES MAYOR DE 18 AÑOS?</h2>

                        <div className="mayor_edad" >
                            <div id="mayoredad" onClick={this.selected_yes}>SÍ</div>
                            <div id="menoredad" onClick={this.selected_no}>NO</div>
                        </div> */}


                    </div>
                    {/* <div className="terminos">
                        Al declarar tu mayoría de edad y acceder a la plataforma, aceptas irrestrictamente los <Link className="url_terminos" to="/terminosycondiciones"><b>Términos y Condiciones</b></Link> del Festival Estéreo Picnic Online, que puede consultar <Link className="url_terminos" to="/terminosycondiciones"><b>aquí.</b></Link> De lo contrario, por favor da click en “No”.
                        </div> */}
                </div>

                <div className="modal_contenedor" onClick={this.selected_no}>
                    <div className="modal">
                        <p> Lo sentimos, debes ser mayor de edad para ingresar a esta página</p>
                    </div>
                </div>
            </section>
        )
    }
}

export default Bienvenida;